<template>
  <div style="width: 100%; text-align: center">
    <splitpanes class="default-theme" :push-other-panes="false" horizontal>
      <pane>
        <div style="margin: 0 auto; display: block; justify-items: center; justify-content: center; width: 100%; padding: 0 auto; overflow-y: auto" >
          <div style="display: flex; width: 100%; justify-content: space-evenly; flex-wrap: wrap; gap: 20px; padding: 15px">
            <a href="#" style="flex: 1 1" @click="showSignalCreateForm = !showSignalCreateForm">Добавить сигнал</a>
            <a href="#" class="disabled" style="flex: 1 1">Удалить сигнал</a>
            <a href="#" style="flex: 1 1" @click="showTemplateMessageForm = !showTemplateMessageForm">Отправить телеграмму</a>
            <a href="#" class="disabled" style="flex: 1 1" @click="showResults" >Результаты расчетов</a>
            <a href="#" class="disabled" style="flex: 1 1">Режим тренажера</a>
            <a href="#" class="disabled" style="flex: 1 1">Снимок карты</a>

            <md-dialog :md-active.sync="showSignalCreateForm"
                       :before-close="() => showSignalCreateForm = false"
            >
              <md-dialog-content style="padding-top: 5px; padding-bottom: 5px">
                <md-dialog-actions>
                  <md-button class="md-primary" @click="showSignalCreateForm = false">Закрыть</md-button>
                </md-dialog-actions>
                <signal-form  @closeSignalForm="handleCloseSignalform"/>
              </md-dialog-content>
            </md-dialog>

            <!--<md-dialog :md-active.sync="showMessageForm" >-->
            <!--<md-dialog-content style="padding: 20px; min-width: 260px">-->
            <!--<message-form :action="selectedAction" @closeMessageForm="handleCloseMessageform" :template="selectedTemplate" @messageCanceled="handleMessageCanceled" @messageFailed="handleMessageFailed" @messageSent="handleMessageSent" :signal-exercise.sync="selectedActionExercise"/>-->
            <!--</md-dialog-content>-->
            <!--</md-dialog>-->

            <md-dialog :md-active.sync="showTemplateMessageForm" >
              <md-dialog-content style="padding: 20px; min-width: 260px">
                <template-message-form role="seismologist" @closeTemplateMessageForm="handleCloseTemplateMessageform"/>
              </md-dialog-content>
            </md-dialog>

            <md-dialog :md-active.sync="showResultForm" >
              <md-dialog-content style="padding: 10px">
                <md-dialog-actions>
                  <md-button class="md-primary" @click="showResultForm = false">Закрыть</md-button>
                </md-dialog-actions>
                <calc-results-form  :signal="selectedSignal"/>
              </md-dialog-content>
            </md-dialog>
          </div>
          <div style="width: 100%; overflow-x: auto">
            <!--{{signals}}-->

            <table id="signals-table" class="table table-striped table-bordered  table-sm" cellspacing="0" width="100%" >
              <thead>
              <tr>
                <th class="is_exersise" ></th>
                <th class="th-sm w70" >Дата
                </th>
                <th class="th-sm w70" >Время
                </th>
                <th class="th-sm w100" >Магнитуда
                </th>
                <th class="th-sm w70" >Широта
                </th>
                <th class="th-sm w70">Долгота
                </th>
                <th class="th-sm w100" >Глубина гипоцентра
                </th>
                <th class="th-sm w70" >Зона
                </th>
                <th class="th-sm w100">Оценка опасности
                </th>
                <th class="scroll_column"></th>
              </tr>
              </thead>
              <tbody >
              <tr v-for="signal in signals" :key="signal.id" @click="showAction(signal)" :class="{'selected-signal': signal.id === selectedSignalId}">
                <td class="is_exersise">
                  <div style="border-radius: 50%; width:18px; height: 18px; background-color: rgba(255,255,255,1); display: flex; justify-items: center; align-items: center" >
                    <div style="border-radius: 50%; width:14px; height: 14px; margin: 2px" :style="{'background-color': signal.exercise ? 'green' : 'red' }" :title="signal.exercise ? 'Учебная' : 'Настоящая'" data-toggle="tooltip"></div>
                  </div>
                </td>
                <td style="min-width: 100px" class="w100">{{getDateH(signal.earthquakeDate)}}</td>
                <td style="min-width: 100px" class="w100">{{getTimeH(signal.earthquakeDate)}}</td>
                <td class="w100">{{signal.magnitude}}</td>
                <td class="w100">{{signal.latitude}}</td>
                <td class="w100">{{signal.longitude}}</td>
                <td class="w100">{{signal.depth || signal.depth === 0 ? signal.depth : '-'}}</td>
                <td class="w100">-</td>
                <td class="w120">{{signal.tsunamigenic ? 'цунамигенная' : 'нецунамигенное'}}</td>
                <!--<td>-</td>-->
                <!--<td>{{signal.exercise ? 'да' : 'нет'}}</td>-->

              </tr>
              </tbody>
              <tfoot>
              <tr v-if="signals.length">
                <th class="is_exersise"></th>
                <th>Дата
                </th>
                <th>Время
                </th>
                <th>Магнитуда
                </th>
                <th>Широта
                </th>
                <th>Долгота
                </th>
                <th>Глубина гипоцентра
                </th>
                <th>Зона
                </th>
                <th class="w120">Оценка опасности
                </th>
                <th class="scroll_column"></th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </pane>
      <pane>
        <pane :min-size="35" size="70">
          <div class="basic-pane">
            <div><span  style="text-decoration: underline; cursor: pointer; opacity: 0.3">Карта</span> / <span>Сообщения</span></div>
            <div style="padding: 10px" ><messages  /></div>
          </div>
        </pane>
      </pane>
    </splitpanes>
  </div>

</template>

<script>
  import {getSignals} from "../api/signal-controller";
  import {doAction} from "../api/action-controller";
  import logger from "../utils/logger";
  import {getTime, getDate} from "../utils/time"
  import {checkSuccess} from "../utils/apiUtil";
  import SignalForm from "../components/base/SignalFormDialog"
  // import MessageForm from "./base/MessageForm"
  import TemplateMessageForm from "../components/base/TemplateMessageForm"
  import CalcResultsForm from "../components/base/CalcResults"
  import Messages from "../components/Messages";
  import {getSeismologistTemplateMessages} from "../api/template-controller";
  import { Splitpanes, Pane } from 'splitpanes'
  import 'splitpanes/dist/splitpanes.css'
  // let signalInterval = null

  export default {
    name: 'Seismologist',
    components: {
      Messages,
      SignalForm,
      TemplateMessageForm,
      CalcResultsForm,
      Splitpanes, Pane
    },
    data() {
      return {
        signals: [],
        action: [],
        selectedSignalId: null,
        selectedSignal: {},
        selectedAction: null,
        selectedActionExercise: null,
        selectedTemplate: {},
        showSignalCreateForm: false,
        showMessageForm: false,
        showTemplateMessageForm: false,
        showResultForm: false,
        showActions: true,
        messageTemplates: [],
        commonTemplates: [],
        resizeDate: null
      }
    },
    computed: {
      isWide() {
        if (this.resizeDate) {
          console.log(this.resizeDate, window.outerWidth)

          if (window.outerWidth < 799) {
            return true
          } else {
            return false
          }
        }
        return window.outerWidth < 799
      }
    },
    methods: {
      handleMessageSent() {
        if (this.selectedAction.messageTemplateId === this.selectedTemplate.id)
          this.selectedAction.done = new Date()
      },
      handleMessageFailed() {
        console.log('Ошибка отправки сообщния')
      },
      handleMessageCanceled() {
        console.log('Отмена отправки сообщения')
        this.handleCloseMessageform()
      },
      async init() {
        let messageTemplates = await getSeismologistTemplateMessages()
        this.messageTemplates = messageTemplates.payload ? messageTemplates.payload : []
        logger('available messages', this.messageTemplates)

        this.signals = []
        let result = await getSignals()
        logger('signals', result)
        this.signals = result.payload ? result.payload : []
      },
      getDateH(date) {
        return getDate(date)
      },
      getTimeH(date) {
        return getTime(date)
      },
      async showAction(signal) {
        this.selectedSignalId = signal.id
        this.selectedSignal = signal
        this.action = null
        // let result = await getAction(this.selectedSignalId)
        // logger(result)
        // this.action = result.payload
        // this.showActions = true
      },
      async act(doing, id, description, hasChildren) {
        if (hasChildren) return;
        // let doing = this.action.find(a => a.id === id && !a.childActions)
        if (doing.childActions) return;
        console.debug('act')
        let doneFlag = await confirm(description, 'Сделано', 'Пропустить')

        this.selectedAction = doing
        this.selectedActionExercise = this.selectedSignal.exercise

        if (doing.messageTemplateId && doneFlag) {
          console.log('template', this.messageTemplates)
          if (this.messageTemplates && this.messageTemplates.length) {
            this.selectedTemplate = this.messageTemplates.find(mt => mt.id === doing.messageTemplateId)
          }
          this.showMessageForm = true
        } else {
          let result = await doAction(this.selectedSignalId, id, doneFlag)
          if (checkSuccess(result)) {
            if (doneFlag) {
              doing.done = true
            } else {
              doing.skipped = true
            }
            this.$forceUpdate();
          }
        }
        console.log('doing result', JSON.stringify(doing))
      },
      showResults() {
        if (!this.selectedSignalId) return;
        this.showResultForm = true
      },
      handleCloseSignalform() {
        this.showSignalCreateForm = false
        this.init()
      },
      handleCloseMessageform() {
        this.showMessageForm = false
        // this.init()
      },
      handleCloseTemplateMessageform() {
        this.showTemplateMessageForm = false
        // this.init()
      }
    },
    async mounted() {
      this.init()
      let that = this
      window.addEventListener('resize', function() {
        that.resizeDate = new Date()
      }, true);

    }
  }
</script>

<style scoped>
  .basic-pane {
    width: 100%;
    /*border: 1px solid black;*/
    margin: 5px 10px 5px 5px;
    flex-grow: 9999;
    min-height: 58vh;
    padding: 5px
  }

  @media (min-width: 1000px){

    table tbody {
      display: block;
      overflow-y: scroll;
      max-height: 110px;
    }

    .action-container {
      max-height: 55vh;
      overflow-y: auto;
    }

    .message-container {
      max-height: calc(56vh - 20px);
      overflow-y: auto;
    }

    .scroll_column {
      width: 15px;
    }

    td {
      line-height: 20px;
      font-size: 14px;
    }

    div.action {
      line-height: 24px;
      font-size: 14px;
    }



  }

  @media (max-width: 400px) {
    .scroll_column {
      width: 0;
      padding: 0;
    }

    .splitpanes.default-theme {
      flex-direction: column !important;
    }

    table tbody, table thead {
      display: block;
      overflow-y: scroll;
      table-layout: auto;
    }

    th.w70, td.w70 {
      min-width: 70px;
      width: 70px;
    }

    th.w100, td.w100 {
      min-width: 100px;
      width: 100px;
    }
  }

  @media (max-width: 1000px){
    th.w70, td.w70 {
      min-width: 70px;
      width: 70px;
    }

    th.w100, td.w100 {
      min-width: 100px;
      width: 100px;
    }
    /*td.w100 {*/
      /*width: 150px;*/
      /*margin: 0;*/
      /*box-sizing: border-box;*/
    /*}*/

    /*th.w100 {*/
      /*width: 100px;*/
      /*box-sizing: border-box;*/
    /*}*/
    td.w120, th.w120 {
      width: 170px;
      margin: 0;
      box-sizing: border-box;
    }


    td {
      line-height: 26px;
      font-size: 20px;
    }

    div.action {
      line-height: 26px;
      font-size: 20px;
    }

    .scroll_column {
      width: 15px;
    }
  }

  table tbody {
    display: block;
    overflow-y: scroll;
  }

  table thead, table tfoot, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .is_exersise {
    width: 50px;
  }

  .selected-signal {
    background-color: rgba(200, 200, 200, 1) !important;
  }

  .splitpanes__splitter {
    cursor: col-resize;
  }

  .act-done {
    color:green; font-weight: 900; font-size: 20px
  }

  .act-skipped {
    color: red; font-weight: 200; font-size: 20px
  }

  #signals-table td {
    padding: 2px;
  }
</style>
